*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    width: 100vw;
}

#root {
    --hover-zoom-scale: 1.1;
    --hover-zoom-transtion: transform 0.5s ease;
    --button-border-radius: 5rem;
    --input-border-radius: 5rem;
    --card-border-radius: 4px;
    background: rgb(228, 225, 255);
    background: linear-gradient(
        90deg,
        rgba(228, 225, 255, 1) 0%,
        rgba(227, 227, 255, 1) 7%,
        rgba(246, 240, 255, 1) 51%,
        rgba(237, 240, 255, 1) 68%,
        rgba(253, 247, 255, 1) 100%
    );
    font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.show {
    display: flex;
}

.hide {
    display: none;
}

ul {
    list-style-type: none;
    padding: 0;
}
