.shift {
    width: 100vw;
    height: 90vh;
    overflow-x: hidden;
    overflow-y: hidden;

    &__month {
        margin: 0 8px;

        display: flex;
        flex-direction: column;

        &__title {
            height: 50px;
        }
    }

    &__day {
        margin: 0 15px 10px 2px;
        border-radius: var(--card-border-radius);
        background-color: #d9d9d9;

        &__title {
            padding: 5px;
        }
    }

    &__cards {
        height: calc(90vh - 50px);
        overflow-y: auto;
    }

    &__card {
        margin: 0 15px 10px 2px;
        padding: 8px;
        border: 2px solid;

        border-radius: var(--card-border-radius);

        &__pending {
            border-color: #008baf;
            cursor: pointer;
        }

        &__selected {
            background-color: #00000099;
            color: white;
            border-color: #b6b6b6a1;
        }

        &__processed {
            border-color: #cdcdcd;
        }

        &__employee {
            display: flex;
            align-items: center;
        }

        &__employee-id {
            font-size: 11px;
            margin: 2px 0 0 5px;

            @media only screen and (min-width: 1921px) {
                font-size: 14px;
            }
        }

        &__button {
            margin: 25px 0 0 -5px;
        }

        &__status {
            font-size: 13px;
            margin: 33px 0 8px;
        }

        &__prompt {
            font-size: 13px;
            margin: 33px 0 8px;
            font-style: italic;
        }
    }
}
