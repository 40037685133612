.header {
    height: 10vh;
    padding: 5px 25px;
    backdrop-filter: blur(5px);
    background-color: transparent;
    z-index: 99;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 860px) {
        padding: 5px 10px;
    }

    @media only screen and (max-width: 610px) {
        display: block;
    }

    &__search-filter {
        margin-right: 20px;
        min-width: 25vw;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        @media only screen and (max-width: 860px) {
            margin-right: 0;
        }

        @media only screen and (max-width: 610px) {
            margin-top: 10px;
        }
    }

    &__action {
        &__text {
            @media only screen and (max-width: 860px) {
                display: none;
            }
        }

        &__icon {
            display: none;

            @media only screen and (max-width: 860px) {
                display: inline;
            }

            @media only screen and (max-width: 610px) {
                position: absolute;
                top: 10vh;
                right: 10px;
            }
        }
    }
}